export const primary = {
    darkBlue: '#020639',
    mediumBlue: '#3B5280',
    blue: '#0D6EFD',
    lightBlue: '#5E9CFF',
    orange: '#FA4D09',
    darkRed: '#C70039',
    red: '#E61B1B',
    lightRed: '#FFA07A',
    green: '#00CC00',
    black: '#221F2E',
    darkGray: '#707070',
    gray: '#93999D',
    lightGray: '#B0B2B5',
    lightYellow: '#FFFACD',
    yellow: '#FFD700',
    darkYellow: '#FFC107',
} as const

export const secondary = {
    lightGray: '#CCC',
    whisperWhite: '#F0F0F0',
    almostWhiteGray: '#F6F7F9',
    lightLimeGreen: '#E2EBBE',
    shadedGreen: '#AEB38E',
    lighterGreen: '#F5FBE1',
    paleBlue: '#DFEFFC',
    focusBlueShadow: '#0D6EFD40',
} as const
