import React from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import Select, { components } from 'react-select'

import { primary, secondary } from 'utils/colors'

interface OptionType {
    value: string | number
    label: string
}

interface CustomSelectProps {
    options: OptionType[]
    onChange: (e: any) => void
    value: { value: any; label: string } | null
    isDisabled?: boolean
    placeholder?: string
    isSearchable?: boolean
    noOptionsMessage?: string
}

function SearchIcon() {
    return <i className="fa-regular fa-magnifying-glass" />
}

function CustomControl({ children, selectProps, ...props }: any) {
    const { isSearchable } = selectProps

    return (
        <components.Control {...props}>
            {isSearchable && <SearchIcon />}
            {children}
        </components.Control>
    )
}

export default function SelectDropdown(defaultProps: CustomSelectProps) {
    const {
        options,
        onChange,
        value,
        isDisabled,
        placeholder,
        isSearchable,
        noOptionsMessage,
    } = defaultProps

    const noOptionsMessageFormat = noOptionsMessage
        ? ({ inputValue }: { inputValue: string }) =>
              `${noOptionsMessage} "${inputValue}"`
        : undefined

    const customStyles = {
        control: (base: any, state: any) => ({
            ...base,
            paddingLeft: isSearchable ? '8px' : '0px',
            color: primary.darkGray,
            boxShadow: state.isFocused
                ? `0 0 0 4px ${secondary.focusBlueShadow}`
                : base.boxShadow,
            transition: 'box-shadow 0.15s',
            border: '1px solid #ced4da',
            '&:hover': {
                borderColor: '#ced4da',
                cursor: 'pointer',
            },
        }),
    }

    return (
        <Select
            options={options}
            onChange={(e: any) => onChange(e.value)}
            value={value}
            isDisabled={isDisabled}
            placeholder={placeholder}
            isSearchable={isSearchable || false}
            noOptionsMessage={noOptionsMessageFormat}
            components={{
                IndicatorSeparator: () => null,
                Control: CustomControl,
            }}
            styles={customStyles}
        />
    )
}
