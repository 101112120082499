import React, { useEffect, useRef, useState } from 'react'
import { Form } from 'react-bootstrap'

import { editLicense, addLicense } from 'api/licenses'
import getPartnersMarginStructures from 'api/partners/getPartnerMarginStructures'
import getPartnersArticles from 'api/partners/getPartnersArticles'
import {
    NumberInput,
    ResponsiveTextInput,
} from 'apps/shared/components/FormInputFields'
import { EmptyListIcon } from 'apps/shared/components/Icons'
import { Modal } from 'apps/shared/components/Modals'
import { CancelSubmitFooter } from 'apps/shared/components/Modals/Footers'
import {
    ErrorMessage,
    FormLabel,
    Switch,
    WarningMessage,
} from 'apps/shared/shared.styled'
import ArticlesDropdownContainer from 'apps/vendor/components/ArticlesDropdownContainer'
import { PartnerType } from 'apps/vendor/components/PartnerList'
import { SelectDropdown } from 'apps/vendor/components/Selects'
import {
    CustomerType,
    LicenseType,
    MarginStructureType,
    SalesTierType,
    LicenseStatus,
    Term,
    TimePeriod,
} from 'apps/vendor/interfaces/subscriptions'
import { ArticleType } from 'apps/vendor/pages/Articles'
import { localeNumber } from 'apps/vendor/utils'
import useToast from 'hooks/useToast'
import useUser from 'hooks/useUser'
import useVendor from 'hooks/useVendor'

import {
    Checkbox,
    DoubleSection,
    MissingAssetText,
    ModalWrapper,
    RemoveListItemButton,
} from '../Modals.styled'
import {
    LicenseBillingSection,
    SalesTierContainer,
    MarginStructureContainer,
} from './components'
import {
    LicenseListItem,
    NumberInputWrapper,
    RadioButtons,
    PriceAdjustmentSection,
    TotalPriceSection,
    ArticleList,
    ArticleSetWrapper,
} from './LicenseModal.styled'

export type ArticleSetType = {
    quantity: number
    article: ArticleType
    description?: string
}

type LicenseModalProps = {
    onClose: () => void
    onSubmit: () => void
    articles: ArticleType[]
    marginStructures: MarginStructureType[]
    partners: PartnerType[]
    preselectedLicense?: LicenseType | null
    licenseCustomer?: string | null
    customers: CustomerType[]
}

export default function LicenseModal(defaultProps: LicenseModalProps) {
    const {
        onClose,
        onSubmit,
        articles,
        marginStructures,
        partners,
        preselectedLicense,
        licenseCustomer,
        customers,
    } = defaultProps
    const { vendor } = useVendor()
    const { user } = useUser()

    const partnerOwnedLicense = preselectedLicense
        ? preselectedLicense.vendor !== vendor?.name
        : false
    const [name, setName] = useState(preselectedLicense?.name || '')

    const [licenseKey, setLicenseKey] = useState(
        preselectedLicense?.license_key || '',
    )
    const [licenseKeyError, setLicenseKeyError] = useState('')

    const [usePartnerArticles, setUsePartnerArticles] = useState(
        (preselectedLicense &&
            preselectedLicense.articles[0].article.vendor !== vendor?.id) ||
            false,
    )

    const [selectedPartner, setSelectedPartner] = useState<PartnerType | null>(
        partners[
            partners.findIndex(
                (partner) =>
                    partner.id.toString() ===
                    preselectedLicense?.articles[0].article.vendor.toString(),
            )
        ] || null,
    )

    const [selectedCustomer, setSelectedCustomer] = useState<
        CustomerType | undefined
    >(undefined)

    const [selectedArticles, setSelectedArticles] = useState<ArticleSetType[]>(
        preselectedLicense?.articles || [],
    )

    const [articleSetDescriptionErrors, setArticleSetDescriptionErrors] =
        useState<{
            [key: number]: string
        }>({})
    const [articleList, setArticleList] = useState<ArticleType[] | null>(null)
    const [articleError, setArticleError] = useState('')

    const [selectedMarginStructure, setSelectedMarginStructure] =
        useState<MarginStructureType | null>(
            preselectedLicense?.margin_structure || null,
        )

    const [selectedSalesTiers, setSelectedSalesTiers] = useState<
        SalesTierType[]
    >(preselectedLicense?.sales_tiers || [])

    const [marginStructureError, setMarginStructureError] = useState('')
    const [salesTierError, setSalesTierError] = useState('')

    const [marginStructureList, setMarginStructureList] = useState<
        MarginStructureType[]
    >([])

    const [selectedStartDate, setSelectedStartDate] = useState(
        preselectedLicense?.start_date || new Date().toISOString().slice(0, 10),
    )

    const [selectedInvoiceStartDate, setSelectedInvoiceStartDate] = useState(
        preselectedLicense
            ? preselectedLicense.sharlic_invoice_period_start_date
            : selectedStartDate,
    )

    const [status, setStatus] = useState<LicenseStatus>(
        preselectedLicense ? preselectedLicense.status : LicenseStatus.ACTIVE,
    )

    const [selectedPaymentFrequency, setSelectedPaymentFrequency] = useState(
        preselectedLicense?.payment_frequency || TimePeriod.MONTHLY,
    )

    const [selectedTerm, setSelectedTerm] = useState(
        preselectedLicense?.term || Term.MONTH,
    )

    const [priceAdjustmentPercentage, setPriceAdjustmentPercentage] = useState<
        number | string
    >(
        Number(preselectedLicense?.rebate) ||
            Number(preselectedLicense?.markup) ||
            '',
    )

    const [isRebate, setIsRebate] = useState(
        Number(preselectedLicense?.markup) === 0 || false,
    )
    const [priceAdjustmentError, setPriceAdjustmentError] = useState('')
    const [priceAdjustmentWarning, setPriceAdjustmentWarning] = useState('')

    const [percentagePerTier, setPercentagePerTier] = useState<string[]>(
        preselectedLicense?.margin_structure?.percentage_per_tier.split(',') ||
            [],
    )
    const [canSubmit, setCanSubmit] = useState(false)
    const { errorToast, successToast } = useToast()

    const [manualInvoicing, setManualInvoicing] = useState<boolean>(
        preselectedLicense?.manual_invoicing || false,
    )

    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
        const initialShowArticleSetDescriptions: { [key: number]: boolean } = {}

        selectedArticles.forEach((article) => {
            initialShowArticleSetDescriptions[article.article.id] =
                (article.description?.length || 0) > 0
        })

        if (preselectedLicense) {
            setSelectedCustomer(
                customers.find(
                    (customer) => customer.id === preselectedLicense.customer,
                ),
            )
        } else if (customers.length === 1) {
            setSelectedCustomer(customers[0])
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const parsedValue = Number(priceAdjustmentPercentage)

        if (isRebate) {
            if (parsedValue < 0 || parsedValue > 100) {
                setPriceAdjustmentError(
                    'Value needs to be a number between 0-100.',
                )
            }
        }

        setPriceAdjustmentWarning(
            parsedValue >= 30
                ? 'You are setting a unusually high value.' +
                      ' Please make sure this is correct.'
                : '',
        )
    }, [
        isRebate,
        priceAdjustmentPercentage,
        selectedInvoiceStartDate,
        selectedStartDate,
    ])

    useEffect(() => {
        let formDirty = true
        let selectedArticlesEdited = true
        let selectedSalesTiersEdited = true
        let selectedCustomerEdited = true
        let priceAdjustmentEdited = true

        if (preselectedLicense) {
            selectedArticlesEdited =
                selectedArticles.length !== preselectedLicense.articles.length

            selectedArticles.forEach((article) => {
                preselectedLicense.articles.forEach(
                    (preselectedArticle: any) => {
                        if (
                            article.article.id === preselectedArticle.article.id
                        ) {
                            if (
                                article.quantity !==
                                    preselectedArticle.quantity ||
                                article.description !==
                                    preselectedArticle.description
                            ) {
                                selectedArticlesEdited = true
                            }
                        }
                    },
                )
            })

            if (
                selectedSalesTiers.length !==
                preselectedLicense.sales_tiers.length
            ) {
                selectedSalesTiersEdited = true
            } else {
                selectedSalesTiersEdited = false
                for (let i = 0; i < selectedSalesTiers.length; i += 1) {
                    if (
                        selectedSalesTiers[i]?.vendor !==
                        preselectedLicense.sales_tiers[i]?.vendor
                    ) {
                        selectedSalesTiersEdited = true
                        break
                    }
                }
            }

            selectedCustomerEdited =
                preselectedLicense.customer !== selectedCustomer?.id

            if (isRebate) {
                priceAdjustmentEdited =
                    Number(priceAdjustmentPercentage) !==
                    Number(preselectedLicense.rebate)
            } else {
                priceAdjustmentEdited =
                    Number(priceAdjustmentPercentage) !==
                    Number(preselectedLicense.markup)
            }

            formDirty =
                preselectedLicense.name !== name ||
                preselectedLicense.license_key !== licenseKey ||
                selectedArticlesEdited ||
                selectedSalesTiersEdited ||
                selectedCustomerEdited ||
                priceAdjustmentEdited ||
                preselectedLicense.term !== selectedTerm ||
                preselectedLicense.payment_frequency !==
                    selectedPaymentFrequency ||
                preselectedLicense.start_date !== selectedStartDate ||
                (preselectedLicense.sharlic_invoice_period_start_date ??
                    null) !== (selectedInvoiceStartDate ?? null) ||
                preselectedLicense.manual_invoicing !== manualInvoicing ||
                preselectedLicense.status !== status
        }

        const requiredFieldsSet =
            name !== '' &&
            selectedCustomer !== undefined &&
            licenseKey !== '' &&
            selectedArticles.length !== 0 &&
            (manualInvoicing ||
                (selectedMarginStructure !== null &&
                    selectedSalesTiers.length ===
                        selectedMarginStructure.number_of_sales_tiers))

        const hasErrors =
            salesTierError !== '' ||
            marginStructureError !== '' ||
            licenseKeyError !== '' ||
            articleError !== '' ||
            priceAdjustmentError !== '' ||
            Object.values(articleSetDescriptionErrors).some(
                (error) => error !== '',
            )

        setCanSubmit(requiredFieldsSet && !hasErrors && formDirty)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        name,
        selectedCustomer,
        licenseKey,
        selectedArticles,
        selectedMarginStructure,
        selectedSalesTiers,
        selectedStartDate,
        selectedTerm,
        selectedPaymentFrequency,
        priceAdjustmentPercentage,
        salesTierError,
        licenseKeyError,
        articleError,
        priceAdjustmentError,
        isRebate,
        selectedInvoiceStartDate,
        status,
        articleSetDescriptionErrors,
        manualInvoicing,
        marginStructureError,
    ])

    useEffect(() => {
        if (!usePartnerArticles && vendor) {
            const tempFilteredArticles = articles.filter(
                (article) =>
                    !selectedArticles.some(
                        (selectedArticle) =>
                            article.id === selectedArticle.article.id,
                    ),
            )

            setArticleList(tempFilteredArticles)
            setMarginStructureList(marginStructures)
            setSelectedPartner(null)
        } else if (partners.length === 1) {
            setSelectedPartner(partners[0])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usePartnerArticles, vendor])

    useEffect(() => {
        if (selectedPartner && selectedPartner.id) {
            getPartnersArticles(selectedPartner.id).then((res) => {
                const data = res.data.filter((article: ArticleType) => {
                    return !selectedArticles.some((selectedArticle) => {
                        return article.id === selectedArticle.article.id
                    })
                })

                setArticleList(data)
            })

            getPartnersMarginStructures(selectedPartner.id).then((res) => {
                setMarginStructureList(res.data)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPartner])

    useEffect(() => {
        const vendorSet = new Set(
            selectedSalesTiers.map((tier) => tier?.vendor?.id),
        )
        const hasDuplicates = vendorSet.size !== selectedSalesTiers.length

        const containsVendor = vendor
            ? selectedSalesTiers.some((tier) => tier?.vendor?.id === vendor.id)
            : false

        const isSalesTiersComplete = !!(
            selectedMarginStructure &&
            selectedSalesTiers.length !==
                selectedMarginStructure.number_of_sales_tiers
        )

        const isVendorRequired = !!(
            vendor &&
            selectedSalesTiers.length ===
                selectedMarginStructure?.number_of_sales_tiers &&
            selectedMarginStructure.number_of_sales_tiers > 1 &&
            !containsVendor
        )

        const marginStructureHasErrors =
            !manualInvoicing &&
            ((status === LicenseStatus.ACTIVE && !selectedMarginStructure) ||
                (status === LicenseStatus.INACTIVE && !selectedMarginStructure))

        let salesTierHasError = ''
        if (isSalesTiersComplete) {
            salesTierHasError = `Please ensure you have selected all
            ${selectedMarginStructure?.number_of_sales_tiers} sales tiers.`
        } else if (hasDuplicates) {
            salesTierHasError =
                'Make sure none of the sales tiers have the same vendor.'
        } else if (isVendorRequired) {
            salesTierHasError =
                'You need to be part of the sales tiers in any level.'
        }

        setSalesTierError(salesTierHasError)

        setMarginStructureError(
            marginStructureHasErrors ? 'Please select a margin structure.' : '',
        )
    }, [
        manualInvoicing,
        selectedInvoiceStartDate,
        selectedMarginStructure,
        selectedSalesTiers,
        status,
        vendor,
    ])

    const handleSubmit = () => {
        if (!canSubmit) {
            return
        }

        const articleSets = selectedArticles.map((article) => ({
            quantity: article.quantity,
            article: article.article.id,
            description: article.description,
        }))

        const licenseData = {
            name,
            customer_id: selectedCustomer?.id,
            rebate: isRebate ? Number(priceAdjustmentPercentage) : 0,
            markup: !isRebate ? Number(priceAdjustmentPercentage) : 0,
            license_key: licenseKey.toString(),
            articles: articleSets,
            margin_structure: manualInvoicing
                ? null
                : selectedMarginStructure?.id,
            sales_tiers: selectedSalesTiers,
            payment_frequency: selectedPaymentFrequency,
            term: selectedTerm,
            start_date: selectedStartDate,
            sharlic_invoice_period_start_date: selectedInvoiceStartDate,
            status,
            manual_invoicing: manualInvoicing,
        }

        const apiCall = preselectedLicense
            ? editLicense(licenseData, preselectedLicense.id)
            : addLicense(licenseData)

        apiCall
            .then(() => {
                onSubmit()

                successToast(
                    preselectedLicense
                        ? `License successfully edited.`
                        : `License successfully added.`,
                )
            })
            .catch((err) => {
                if (err.response.status === 409) {
                    return setLicenseKeyError('License key already exists.')
                }
                if (err.response.status !== 500) {
                    const process = preselectedLicense ? 'edit' : 'add'

                    return errorToast(
                        `Failed to ${process} license, please try again.`,
                    )
                }

                return errorToast(
                    // eslint-disable-next-line max-len
                    'Unknown error. Please try again and contact Sharlic support if error persists.',
                )
            })
    }

    const renderMissingAsset = (text: string) => {
        return <MissingAssetText>{text}</MissingAssetText>
    }

    const handleSetLicenseKey = (key: string) => {
        setLicenseKeyError('')
        setLicenseKey(key)
    }

    const checkValidArticleCurrencies = (articleSetList: ArticleSetType[]) => {
        if (articleSetList.length === 0) {
            return
        }

        const { currency } = articleSetList[0].article

        const matchingCurrencies = articleSetList.every(
            (article) => article.article.currency === currency,
        )

        if (!matchingCurrencies) {
            setArticleError('All articles must have the same currency.')

            return
        }
        setArticleError('')
    }

    const handleAddArticle = (article: ArticleType) => {
        const tempSelectedArticleList = [
            ...selectedArticles,
            { quantity: 1, article },
        ]
        setSelectedArticles(tempSelectedArticleList)

        if (!articleList) {
            return
        }
        setArticleList(articleList.filter((item) => item !== article))

        checkValidArticleCurrencies(tempSelectedArticleList)
    }

    const handleRemoveArticle = (article: ArticleType) => {
        const tempSelectedArticleList = selectedArticles.filter(
            (item) => item.article.id !== article.id,
        )

        setSelectedArticles(tempSelectedArticleList)
        if (!articleList) {
            return
        }
        setArticleList([...articleList, article])

        checkValidArticleCurrencies(tempSelectedArticleList)
    }

    const handleUpdateArticleQuantity = (
        quantity: string,
        article: ArticleType,
    ) => {
        if (quantity === '0') {
            setArticleError(
                'Quantity must be greater than zero. Remove article instead.',
            )
        } else {
            setArticleError('')
        }

        const updatedArticles = selectedArticles.map((item) => {
            if (item.article.id === article.id) {
                return {
                    ...item,
                    quantity: parseInt(quantity, 10),
                }
            }

            return item
        })
        setSelectedArticles(updatedArticles)
    }

    const handleUpdateArticleDescription = (
        description: string,
        article: ArticleType,
    ) => {
        const errorMessage =
            description.length > 120
                ? 'Description must be less than 120 characters.'
                : ''

        setArticleSetDescriptionErrors(
            (prevState: { [key: string]: string }) => ({
                ...prevState,
                [article.id]: errorMessage,
            }),
        )

        if (errorMessage) {
            return
        }

        const updatedSelectedArticles = selectedArticles.map((item) =>
            item.article.id === article.id ? { ...item, description } : item,
        )
        setSelectedArticles(updatedSelectedArticles)
    }

    const handleSetSalesTiers = (order: number, id: string) => {
        if (!vendor) {
            return
        }

        const tempTierList = [...selectedSalesTiers]

        const indexToReplace = tempTierList.findIndex(
            (tier) => tier.order === order,
        )

        const selectedTierPartner = partners.find(
            (partner) => partner.id.toString() === id,
        )

        if (indexToReplace !== -1) {
            tempTierList[indexToReplace] = {
                order,
                vendor: selectedTierPartner || vendor,
            }
        } else {
            tempTierList.push({
                order,
                vendor: selectedTierPartner || vendor,
            })
        }

        setSelectedSalesTiers(tempTierList)
    }

    const handleSetSelectedPartner = (index: number) => {
        const partner = partners[index]

        if (selectedPartner === partner) {
            return
        }

        setSelectedPartner(partner)
        setSelectedSalesTiers([{ order: 1, vendor: partner }])
        setSelectedArticles([])
        setSelectedMarginStructure(null)
        setSelectedSalesTiers([])
    }

    const handleSetMarginStructure = (id: string) => {
        const filteredMarginStructure = marginStructureList.find(
            (marginStructure) => marginStructure.id === parseInt(id, 10),
        )

        setSelectedSalesTiers([])
        if (!filteredMarginStructure) {
            return
        }
        setSelectedMarginStructure(filteredMarginStructure)

        setPercentagePerTier(
            filteredMarginStructure.percentage_per_tier.split(','),
        )

        const vendorToUse =
            usePartnerArticles && selectedPartner ? selectedPartner : vendor

        const salesTiers = [
            { order: 1, vendor: vendorToUse },
            ...(filteredMarginStructure.number_of_sales_tiers === 2 &&
            vendor !== vendorToUse
                ? [{ order: 2, vendor }]
                : []),
        ] as SalesTierType[]

        setSelectedSalesTiers(salesTiers)
    }

    const renderPartnerDropdown = () => {
        if (!usePartnerArticles) {
            return null
        }

        const options = partners.map((partner, index) => ({
            value: index,
            label: partner.partner_name,
        }))

        const value = selectedPartner
            ? {
                  value: partners.findIndex(
                      (partner) => partner.id === selectedPartner.id,
                  ),
                  label: selectedPartner.partner_name,
              }
            : null

        return (
            <SelectDropdown
                options={options}
                value={value}
                onChange={(index: number) => handleSetSelectedPartner(index)}
                placeholder="Choose partner"
                isSearchable
                isDisabled={partners.length < 2}
                noOptionsMessage="No matching partner"
            />
        )
    }

    const renderErrorMessage = (message: string) => {
        if (!message) {
            return null
        }

        return <ErrorMessage>{message}</ErrorMessage>
    }

    const renderResponsiveTextInput = (article: ArticleSetType) => (
        <ResponsiveTextInput
            value={article.description?.toString() || ''}
            placeholder="Comment to show on invoice"
            onChange={(value) =>
                handleUpdateArticleDescription(value, article.article)
            }
            onBlur={(value) =>
                handleUpdateArticleDescription(value.trim(), article.article)
            }
            disabled={partnerOwnedLicense}
        />
    )

    const renderArticleListItem = (article: ArticleSetType) => (
        <LicenseListItem key={article.article.id}>
            <section id="info-section">
                <span>{article.article.name}</span>
                <span>{`${localeNumber(Number(article.article.msrp), {
                    locale: user?.locale,
                })} ${article.article.currency}`}</span>
                {renderResponsiveTextInput(article)}
            </section>
            <div>
                <NumberInputWrapper
                    value={article.quantity.toString()}
                    onChange={(e) =>
                        handleUpdateArticleQuantity(e, article.article)
                    }
                    config={{
                        min: 0,
                        max: 999999,
                        allowEmpty: false,
                        allowLeadingZero: false,
                        allowDecimal: { isEnabled: false },
                    }}
                    disabled={partnerOwnedLicense}
                />
                {!partnerOwnedLicense && (
                    <RemoveListItemButton
                        onClick={() => handleRemoveArticle(article.article)}
                        className="fas fa-times fa-lg"
                        onKeyDown={(e) => {
                            if (e.key === 'Enter')
                                handleRemoveArticle(article.article)
                        }}
                        role="button"
                        tabIndex={0}
                        aria-label="Remove article"
                    />
                )}
            </div>
        </LicenseListItem>
    )

    const renderSelectedArticles = () => (
        <ArticleList>
            {selectedArticles.map((article) => (
                <React.Fragment key={article.article.id}>
                    <ArticleSetWrapper>
                        {renderArticleListItem(article)}
                    </ArticleSetWrapper>
                    {renderErrorMessage(
                        articleSetDescriptionErrors[article.article.id],
                    )}
                </React.Fragment>
            ))}
        </ArticleList>
    )

    const renderSelectedArticlesSection = () => {
        const html =
            selectedArticles.length === 0 ? (
                <EmptyListIcon insideModal />
            ) : (
                renderSelectedArticles()
            )

        return (
            <>
                {html}
                {renderErrorMessage(articleError)}
            </>
        )
    }

    const renderLicenseTotalPrice = () => {
        if (selectedArticles.length === 0) {
            return null
        }

        let totalPrice = selectedArticles.reduce(
            (totalValue, article) =>
                totalValue + Number(article.article.msrp) * article.quantity,
            0,
        )

        if (isRebate) {
            totalPrice *= 1 - Number(priceAdjustmentPercentage) / 100
        } else {
            totalPrice *= 1 + Number(priceAdjustmentPercentage) / 100
        }

        return (
            <TotalPriceSection>
                <p>Total price:</p>
                <p>
                    {`${localeNumber(totalPrice, { locale: user?.locale })} ${
                        selectedArticles[0].article.currency
                    }`}
                </p>
            </TotalPriceSection>
        )
    }

    const handleArticleRadioChange = () => {
        setUsePartnerArticles(!usePartnerArticles)
        setSelectedArticles([])
        setSelectedPartner(null)
        setSelectedMarginStructure(null)
        setArticleList(null)
        setSelectedSalesTiers([])
    }

    const renderArticleRadioButtons = () => {
        if (partners.length === 0 || partnerOwnedLicense) {
            return null
        }

        return (
            <RadioButtons>
                <Checkbox
                    {...{
                        type: 'radio',
                        id: 'my-articles',
                        name: 'article-options',
                        label: 'My articles',
                        defaultChecked: !usePartnerArticles,
                        onChange: () => handleArticleRadioChange(),
                    }}
                />
                <Checkbox
                    {...{
                        type: 'radio',
                        id: 'partner-articles',
                        name: 'article-options',
                        label: 'Partners articles',
                        defaultChecked: usePartnerArticles,
                        onChange: () => handleArticleRadioChange(),
                    }}
                />
            </RadioButtons>
        )
    }

    const renderArticlesDropdownContainer = () => {
        if (partnerOwnedLicense) {
            return null
        }

        return (
            <ArticlesDropdownContainer
                selectedArticles={selectedArticles}
                articleList={articleList}
                usePartnerArticles={usePartnerArticles}
                selectedPartner={selectedPartner}
                handleAddArticle={handleAddArticle}
            />
        )
    }

    const renderArticleSection = () => {
        return (
            <>
                {renderArticlesDropdownContainer()}
                {renderSelectedArticlesSection()}
            </>
        )
    }

    const renderNameField = () => {
        return (
            <>
                <FormLabel>Name</FormLabel>
                <Form.Control
                    {...{
                        ref: inputRef,
                        placeholder: 'License name',
                        value: name,
                        type: 'text',
                        onChange: (e: any) => setName(e.target.value),
                        onBlur: (e: any) => setName(e.target.value.trim()),
                        disabled: partnerOwnedLicense,
                    }}
                />
            </>
        )
    }

    const renderLicenseKeyField = () => {
        return (
            <>
                <FormLabel>License Key</FormLabel>
                <Form.Control
                    {...{
                        placeholder: 'License/Agreement identifier',
                        value: licenseKey,
                        type: 'text',
                        onChange: (e: any) =>
                            handleSetLicenseKey(e.target.value),
                        onBlur: (e: any) =>
                            handleSetLicenseKey(e.target.value.trim()),
                        disabled: partnerOwnedLicense,
                    }}
                />
                {!partnerOwnedLicense && renderErrorMessage(licenseKeyError)}
            </>
        )
    }

    const onCustomerSelected = (id: number) => {
        setSelectedCustomer(customers.find((obj) => obj.id === id))
    }

    const renderCustomerDropdown = () => {
        let html = null

        if (!partnerOwnedLicense && customers.length === 0) {
            html = renderMissingAsset(
                'Create a customer before adding a license.',
            )
        } else {
            const options = customers.map((customer) => ({
                label: customer.name,
                value: customer.id,
            }))

            const value = selectedCustomer
                ? {
                      label: selectedCustomer.name,
                      value: selectedCustomer.id,
                  }
                : null

            html = (
                <SelectDropdown
                    options={options}
                    onChange={onCustomerSelected}
                    value={value}
                    isDisabled={partnerOwnedLicense || customers.length < 2}
                    placeholder={
                        (partnerOwnedLicense
                            ? licenseCustomer
                            : selectedCustomer?.name) || 'Choose customer'
                    }
                    isSearchable
                    noOptionsMessage="No matching customer"
                />
            )
        }

        return (
            <>
                <FormLabel>Customer</FormLabel>
                {html}
            </>
        )
    }

    const validatePriceAdjustment = (value: string) => {
        const splitValue = value.split('.')
        if (splitValue[1]?.length > 2) {
            setPriceAdjustmentError('Max two decimals allowed.')
        } else if (value.endsWith('.')) {
            setPriceAdjustmentError('Do not end on a decimal point.')
        } else if (splitValue[0]?.length > 3) {
            setPriceAdjustmentError('Max three digits before decimal allowed.')
        } else {
            setPriceAdjustmentError('')
        }
    }

    const onPriceAdjustmentChanged = (value: string) => {
        validatePriceAdjustment(value)
        setPriceAdjustmentPercentage(value)
    }

    const handlePriceAdjustmentRadioChange = () => {
        setIsRebate(!isRebate)
    }

    const renderPriceAdjustmentWarning = () => {
        if (!priceAdjustmentWarning || priceAdjustmentError) {
            return null
        }

        return <WarningMessage>{priceAdjustmentWarning}</WarningMessage>
    }

    const renderPriceAdjustmentRadioButtons = () => {
        return (
            <RadioButtons>
                <Checkbox
                    {...{
                        type: 'radio',
                        id: 'rebate',
                        name: 'rebate-markup-options',
                        label: 'Rebate',
                        defaultChecked: isRebate,
                        onChange: () => handlePriceAdjustmentRadioChange(),
                        disabled: partnerOwnedLicense,
                    }}
                />
                <Checkbox
                    {...{
                        type: 'radio',
                        id: 'markup',
                        name: 'rebate-markup-options',
                        label: 'Markup',
                        defaultChecked: !isRebate,
                        onChange: () => handlePriceAdjustmentRadioChange(),
                        disabled: partnerOwnedLicense,
                    }}
                />
            </RadioButtons>
        )
    }

    const renderPriceAdjustment = () => {
        return (
            <>
                <FormLabel>
                    Rebate/Markup (%)<span> (optional)</span>
                </FormLabel>
                <DoubleSection>
                    <div>
                        <PriceAdjustmentSection>
                            {renderPriceAdjustmentRadioButtons()}
                            <NumberInput
                                placeholder="-"
                                value={priceAdjustmentPercentage.toString()}
                                onChange={onPriceAdjustmentChanged}
                                config={{
                                    min: 0,
                                    max: 9999,
                                    allowLeadingZero: false,
                                    allowDecimal: { maxDecimalPlaces: 3 },
                                }}
                                disabled={partnerOwnedLicense}
                            />
                        </PriceAdjustmentSection>
                        {renderPriceAdjustmentWarning()}
                        {renderErrorMessage(priceAdjustmentError)}
                    </div>
                    <div>{renderLicenseTotalPrice()}</div>
                </DoubleSection>
            </>
        )
    }

    const handleSetActiveState = () => {
        setStatus(
            status === LicenseStatus.ACTIVE
                ? LicenseStatus.INACTIVE
                : LicenseStatus.ACTIVE,
        )
    }

    const renderToggleActiveSwitch = () => {
        return (
            <Switch
                id="license-active-switch"
                checked={status !== LicenseStatus.INACTIVE}
                label="License active"
                onChange={() => handleSetActiveState()}
                disabled={partnerOwnedLicense}
            />
        )
    }

    const handleSetTerm = (index: number) => {
        setSelectedTerm(Object.values(Term)[index])
        const frequency = Object.values(TimePeriod)[index]
        setSelectedPaymentFrequency(frequency)
    }

    const renderTermDropdown = () => {
        const options = Object.values(Term).map((term, index) => ({
            value: index,
            label: term,
        }))

        const selectedOption = options.find(
            (option) => option.label === selectedTerm,
        )

        const value = selectedOption
            ? {
                  value: selectedOption.value,
                  label: selectedOption.label.toString(),
              }
            : null

        return (
            <>
                <FormLabel>Term</FormLabel>
                <SelectDropdown
                    options={options}
                    value={value}
                    onChange={handleSetTerm}
                    isDisabled={partnerOwnedLicense}
                />
            </>
        )
    }

    const handleSetPaymentFrequency = (index: number) => {
        setSelectedPaymentFrequency(Object.values(TimePeriod)[index])
    }

    const renderLicenseBillingSection = () => {
        const preselectedValues = {
            licenseStatus: status,
            term: selectedTerm,
            paymentFrequency: selectedPaymentFrequency,
            startDate: selectedStartDate,
            invoiceStartDate: selectedInvoiceStartDate,
            manualInvoicing,
        }

        return (
            <LicenseBillingSection
                preselectedValues={preselectedValues}
                partnerOwnedLicense={partnerOwnedLicense}
                onChangeStatus={setStatus}
                onChangePaymentFrequency={handleSetPaymentFrequency}
                onChangeStartDate={setSelectedStartDate}
                onChangeInvoiceStartDate={setSelectedInvoiceStartDate}
                onChangeManualInvoicing={setManualInvoicing}
            />
        )
    }

    const getTitle = () => {
        let title = preselectedLicense ? 'Edit' : 'New'
        title = partnerOwnedLicense ? 'View' : title

        return (
            <>
                {title}
                {partnerOwnedLicense && (
                    <span>Created by: {preselectedLicense?.vendor}</span>
                )}
            </>
        )
    }

    const renderPartnerSelectSection = () => {
        if (partnerOwnedLicense) {
            return null
        }

        return (
            <>
                <FormLabel>Articles</FormLabel>
                <DoubleSection>
                    <div>{renderArticleRadioButtons()}</div>
                    <div style={{ marginBottom: '-5px' }}>
                        {renderPartnerDropdown()}
                    </div>
                </DoubleSection>
            </>
        )
    }

    const marginStructureInfo = {
        marginStructureList,
        selectedMarginStructure,
        handleSetMarginStructure,
        usePartnerArticles,
        marginStructureError,
        partnerOwnedLicense,
        selectedPartner,
        renderMissingAsset,
    }

    const salesTierInfo = {
        percentagePerTier,
        selectedSalesTiers,
        numberOfSalesTiers: selectedMarginStructure?.number_of_sales_tiers,
        handleSetSalesTiers,
        partners,
        usePartnerArticles,
        partnerOwnedLicense,
        salesTierError,
    }

    const renderMarginStructureSection = () => {
        if (manualInvoicing) {
            return null
        }

        return (
            <>
                <MarginStructureContainer {...marginStructureInfo} />
                <SalesTierContainer {...salesTierInfo} />
            </>
        )
    }

    return (
        <Modal
            size="lg"
            onClose={onClose}
            title={getTitle()}
            body={
                <ModalWrapper>
                    {renderToggleActiveSwitch()}
                    <DoubleSection>
                        <div>{renderNameField()}</div>
                        <div>{renderLicenseKeyField()}</div>
                    </DoubleSection>
                    {renderCustomerDropdown()}
                    {renderPartnerSelectSection()}
                    {renderArticleSection()}
                    {renderPriceAdjustment()}
                    {renderTermDropdown()}
                    {renderLicenseBillingSection()}
                    {renderMarginStructureSection()}
                </ModalWrapper>
            }
            footer={
                partnerOwnedLicense ? null : (
                    <CancelSubmitFooter
                        onClose={onClose}
                        onSubmit={handleSubmit}
                        canSubmit={canSubmit}
                    />
                )
            }
        />
    )
}
