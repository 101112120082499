import React from 'react'
import { FormLabel } from 'react-bootstrap'

import { ErrorMessage } from 'apps/shared/shared.styled'
import { SelectDropdown } from 'apps/vendor/components/Selects'
import { MarginStructureType } from 'apps/vendor/interfaces/subscriptions'

interface MarginStructureContainerProps {
    marginStructureList: MarginStructureType[]
    selectedMarginStructure: MarginStructureType | null
    handleSetMarginStructure: (marginStructureId: string) => void
    usePartnerArticles: boolean
    partnerOwnedLicense: boolean
    marginStructureError?: string
    renderMissingAsset: (message: string) => JSX.Element
}

export default function MarginStructureContainer(
    defaultProps: MarginStructureContainerProps,
) {
    const {
        marginStructureList,
        selectedMarginStructure,
        handleSetMarginStructure,
        partnerOwnedLicense,
        usePartnerArticles,
        renderMissingAsset,
        marginStructureError,
    } = defaultProps

    if (partnerOwnedLicense) {
        return null
    }

    const getSelectOptions = () => {
        let availableMarginStructures = marginStructureList

        if (usePartnerArticles) {
            availableMarginStructures = availableMarginStructures.filter(
                (marginStructure) =>
                    marginStructure.number_of_sales_tiers !== 1,
            )
        }

        return availableMarginStructures.map((marginStructure) => ({
            value: marginStructure.id,
            label: marginStructure.name,
        }))
    }

    const renderDropdown = () => {
        const options = getSelectOptions()

        const value = selectedMarginStructure
            ? {
                  value: selectedMarginStructure.id,
                  label: selectedMarginStructure.name,
              }
            : null

        return (
            <SelectDropdown
                options={options}
                onChange={handleSetMarginStructure}
                value={value}
                placeholder={
                    marginStructureList.length > 0
                        ? 'Choose margin structure'
                        : 'No margin structures available'
                }
                isSearchable
                noOptionsMessage="No matching margin structure"
                isDisabled={marginStructureList.length === 0}
            />
        )
    }

    const renderMissingAssetMessage = () =>
        renderMissingAsset(
            usePartnerArticles
                ? 'No margin structures available for this partner.'
                : 'Create a margin structure before adding a license.',
        )

    return (
        <>
            <FormLabel>Margin Structure</FormLabel>
            {marginStructureList.length === 0
                ? renderMissingAssetMessage()
                : renderDropdown()}
            {marginStructureError && (
                <ErrorMessage>{marginStructureError}</ErrorMessage>
            )}
        </>
    )
}
